import React, { useState } from "react";
import EmailInput from "@Molecules/common/auth/emailInput";
import PasswordInput from "@Molecules/common/auth/passwordInput";
import { AuthenticationChallenge } from "@Types";
import { LegacyCard } from "@shopify/polaris";
import { useTranslation } from "next-i18next";
import SignInButton from "@Molecules/common/auth/signinButton";
import useUserCountry from "../hooks/useUserCountry";

type Props = {
  ip: string;
  setChallengeName: (challengeName: AuthenticationChallenge) => void;
  setTimeBaseCode: (code: string) => void;
  setUser: (user: any) => void;
};

export default function SigninForm({
  setChallengeName,
  ip,
  setUser,
  setTimeBaseCode,
}: Props) {
  const { isNotAllowed, country } = useUserCountry();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation("login");

  return (
    <div>
      <LegacyCard sectioned title={t("login_title")}>
        <EmailInput
          label={t("email_label")}
          email={email}
          setEmail={setEmail}
        />{" "}
        <br />
        <PasswordInput password={password} setPassword={setPassword} />
        <SignInButton
          email={email}
          setUser={setUser}
          ip={ip}
          password={password}
          isNotAllowed={isNotAllowed}
          setChallengeName={setChallengeName}
          setTimeBaseCode={setTimeBaseCode}
        />
      </LegacyCard>
      {isNotAllowed && (
        <div className="mt-4 mb-4">
          <LegacyCard sectioned>
            <p className="text-[red]">
              You're trying to log in from {country}, a restricted area. Our
              service is not available in your region.
            </p>
          </LegacyCard>
        </div>
      )}
    </div>
  );
}
